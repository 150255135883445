/* eslint-disable max-len */
import moment from 'moment-timezone';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DateOptions, FilterOptions, ServiceFilterTranslation } from '../../../../models/notification-history.model';

const ALL = 'all';

const today = (): moment.Moment => moment().startOf('day');
const yesterdayFrom = (): moment.Moment => moment().subtract(1, 'days').startOf('day');
const yesterdayTo = (): moment.Moment => moment().subtract(1, 'days').endOf('day');
const thisWeekFrom = (): moment.Moment => moment().subtract(0, 'weeks').startOf('week');
const thisWeekTo = (): moment.Moment => moment();
const lastWeekFrom = (): moment.Moment => moment().subtract(1, 'weeks').startOf('week');
const lastWeekTo = (): moment.Moment => moment().subtract(1, 'weeks').endOf('week');
const thisMonthFrom = (): moment.Moment => moment().subtract(0, 'months').startOf('month');
const thisMonthTo = (): moment.Moment => moment();
const lastMonthFrom = (): moment.Moment => moment().subtract(1, 'months').startOf('month');
const lastMonthTo = (): moment.Moment => moment().subtract(1, 'months').endOf('month');

@Component({
  selector: 's2a-pure-timeline-filter',
  templateUrl: './pure-timeline-filter.component.html',
  styleUrls: ['./pure-timeline-filter.component.scss'],
})
export class PureTimelineFilterComponent {
  /**
   * Emits an cancel click event
   *
   * @returns void
   */
  @Output() cancelClicked = new EventEmitter<void>();

  /**
   * Triggered when filter should be applied.
   *
   * @returns FilterOptions
   */
  @Output() applyFilterClicked = new EventEmitter<FilterOptions>();

  /**
   * @ignore
   */
  allServices = [
    { key: ALL, value: 'global.all' },
    ...ServiceFilterTranslation.sort((a, b) => (a.key > b.key ? 1 : -1)),
  ];

  /**
   * @ignore
   */
  today = today().toDate().toISOString();

  /**
   * @ignore
   */
  dateOptions = DateOptions;

  /**
   * @ignore
   */
  selectedServices = [ALL];

  /**
   * @ignore
   */
  selectedDateOption: '' | DateOptions = '';

  /**
   * @ignore
   */
  dateRange = {
    start: '',
    end: '',
  };

  /**
   * @ignore
   */
  selectedStartDateInMs: number | undefined;

  /**
   * @ignore
   */
  selectedEndDateInMs: number | undefined;

  /**
   * Filter option to pre-fill the filter menu
   *
   * @optional
   */
  @Input() set filterOptions(options: FilterOptions) {
    this.initFilterOptions(options);
  }

  /**
   * @ignore
   */
  isSelectedService(service: string): boolean {
    return this.selectedServices.includes(service);
  }

  /**
   * @ignore
   */
  onSelectService(service: string): void {
    if (service === ALL) {
      this.selectedServices = [ALL];
    } else {
      this.removeFromArray(ALL);
    }

    if (!this.selectedServices.includes(service)) {
      this.selectedServices.push(service);
    } else {
      this.removeFromArray(service);
    }

    if (!this.selectedServices.length) {
      this.selectedServices.push(ALL);
    }

    if (this.selectedServices.length === this.allServices.length - 1) {
      this.selectedServices = [ALL];
    }
  }

  /**
   * @ignore
   */
  isSelectedDateOption(option: string): boolean {
    return this.selectedDateOption === option;
  }

  /**
   * @ignore
   */
  onSelectDateOption(option: DateOptions): void {
    if (this.isSelectedDateOption(option)) {
      this.selectedDateOption = '';
    } else {
      this.selectedDateOption = option;

      switch (option) {
        case 'yesterday':
          this.selectedStartDateInMs = yesterdayFrom().toDate().getTime();
          this.selectedEndDateInMs = yesterdayTo().toDate().getTime();
          break;
        case 'this_week':
          this.selectedStartDateInMs = thisWeekFrom().toDate().getTime();
          this.selectedEndDateInMs = thisWeekTo().toDate().getTime();
          break;
        case 'last_week':
          this.selectedStartDateInMs = lastWeekFrom().toDate().getTime();
          this.selectedEndDateInMs = lastWeekTo().toDate().getTime();
          break;
        case 'this_month':
          this.selectedStartDateInMs = thisMonthFrom().toDate().getTime();
          this.selectedEndDateInMs = thisMonthTo().toDate().getTime();
          break;
        case 'last_month':
          this.selectedStartDateInMs = lastMonthFrom().toDate().getTime();
          this.selectedEndDateInMs = lastMonthTo().toDate().getTime();
          break;
        case 'custom':
          break;
      }
    }
  }

  /**
   * @ignore
   */
  onSelectDateRange(dateRange: CustomEvent<string> | Event): void {
    const detail: string = 'detail' in dateRange ? dateRange.detail : '';
    const range = JSON.parse(detail as string);

    this.dateRange.start = range.start;
    this.selectedStartDateInMs = new Date(range.start as string | number | Date).getTime();

    this.dateRange.end = range.end;
    this.selectedEndDateInMs = new Date(range.end as string | number | Date).getTime();
  }

  /**
   * @ignore
   */
  onResetFilter(): void {
    this.selectedServices = [ALL];
    this.selectedDateOption = '';
    this.dateRange = {
      start: '',
      end: '',
    };
    this.selectedStartDateInMs = undefined;
    this.selectedEndDateInMs = undefined;
  }

  /**
   * @ignore
   */
  onApplyFilter(): void {
    this.applyFilterClicked.emit({
      services: !this.selectedServices.includes(ALL) ? this.selectedServices : undefined,
      date: !this.isSelectedDateOption('')
        ? {
            identifier: this.selectedDateOption as DateOptions,
            from: this.selectedStartDateInMs ?? 0,
            to: this.selectedEndDateInMs ?? 0,
          }
        : undefined,
    });
  }

  /**
   * @ignore
   */
  onCancelClicked(): void {
    this.initFilterOptions(this.filterOptions);
    this.cancelClicked.emit();
  }
  /**
   * @ignore
   */
  private removeFromArray(item: string): void {
    const index = this.selectedServices.indexOf(item);
    if (index > -1) {
      this.selectedServices.splice(index, 1);
    }
  }

  /**
   * @ignore
   */
  private initFilterOptions(options: FilterOptions): void {
    if (options && options.services) {
      this.selectedServices = options.services;
    }

    if (options && options.date) {
      this.selectedDateOption = options.date.identifier;
      this.selectedStartDateInMs = options.date.from;
      this.selectedEndDateInMs = options.date.to;

      if (this.isSelectedDateOption('custom')) {
        this.dateRange.start = new Date(options.date.from).toISOString();
        this.dateRange.end = new Date(options.date.to).toISOString();
      }
    }
  }
}
